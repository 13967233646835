@import './fonts';
@import './variables';
@import './typography';
@import './global';

.wysiwyg {
    @apply text-gray-300 text-16;

    & > p {
        @apply text-gray-300 text-16;
    }
}

.wysiwyg__map {
    @apply text-gray-300 text-14;
    
    & > p {
        @apply text-gray-300 text-14;
    }
}