mat-slider.mat-mdc-slider .mdc-slider__thumb-knob,
mat-slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
    @apply bg-primary border-primary;
}

mat-slider.mat-mdc-slider .mat-ripple .mat-mdc-slider-active-ripple,
mat-slider.mat-mdc-slider .mat-ripple .mat-mdc-slider-focus-ripple {
    @apply bg-primary/5;
}

mat-slider.mat-mdc-slider .mdc-slider__track--inactive {
    @apply bg-gray-300;
}

mat-slider.mat-mdc-slider .mdc-slider__track--active_fill {
    @apply border-primary;
}