@layer base {
  :root {
      --c-primary: 0 123 255;
      --c-primary-variant: 3 49 106;
      --c-secondary: 251 253 255;
      --c-secondary-variant: 233 243 255;
    
      --c-blue-100: 251 253 255;
      --c-blue-200: 245 249 255;
      --c-blue-300: 233 243 255;
      --c-blue-400: 201 226 255;
      --c-blue-500: 177 213 255;
      --c-blue-600: 111 170 255;
      --c-blue-700: 0 123 255;
      --c-blue-800: 0 109 240;
      --c-blue-900: 3 49 106;
     
      --c-gray-100: 250 250 250;
      --c-gray-200: 188 195 210;
      --c-gray-300: 124 139 167;
     
      --c-yellow-100: 249 222 4;

      --c-green-100: 62 213 152;
      --c-orange-100: 245 146 53;
      --c-purple-100: 206 147 216;
     
      --c-infos-100: 224 245 255;
      --c-infos-200: 0 176 255;
      --c-infos-300: 1 38 61;
     
      --c-warning-100: 255 242 233;
      --c-warning-200: 226 111 32;
      --c-warning-300: 57 28 8;
     
      --c-success-100: 243 251 247;
      --c-success-200: 74 201 126;
      --c-success-300: 18 43 29;

      --c-error-200: 240 58 56;
     
      --c-background: 250 250 250;
      --c-surface: 255 255 255;

      --c-white: 255 255 255;
      --c-black: 22 23 30;
      --c-black-2: 217 217 217;
      --c-gradient: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.24) 37.5%, rgb(0 0 0) 95.5%);
  }
}

$font-primary: 'Poppins', sans-serif;
$f-primary: var(--f-primary);
$f-secondary: var(--f-secondary);

//old colors
$c-primary: rgb(var(--c-primary));
$c-primary-variant: rgb(var(--c-primary-variant));
$c-secondary: rgb(var(--c-secondary));
$c-secondary-variant: rgb(var(--c-secondary-variant));
$c-white: rgb(var(--c-white));
$c-black: rgb(var(--c-black));
$c-black-2: rgb(var(--c-black-2));
$c-gradient: var(--c-gradient);

// Blue Shades
$color-blue: (
  100: var(--c-blue-100),
  200: var(--c-blue-200),
  300: var(--c-blue-300),
  400: var(--c-blue-400),
  500: var(--c-blue-500),
  600: var(--c-blue-600),
  700: var(--c-blue-700),
  800: var(--c-blue-800),
  900: var(--c-blue-900)
);

// gray Shades
$color-gray: (
  100: var(--c-gray-100),
  200: var(--c-gray-200),
  300: var(--c-gray-300)
);

// Other Colors
$color-yellow: (
  100: var(--c-yellow-100)
);
$color-green: (
  100: var(--c-green-100)
);
$color-orange: (
  100: var(--c-orange-100)
);
$color-purple: (
  100: var(--c-purple-100)
);

// Info Colors
$color-infos: (
  100: var(--c-infos-100),
  200: var(--c-infos-200),
  300: var(--c-infos-300)
);

// Warning Colors
$color-warning: (
  100: var(--c-warning-100),
  200: var(--c-warning-200),
  300: var(--c-warning-300)
);

// Success Colors
$color-success: (
  100: var(--c-success-100),
  200: var(--c-success-200),
  300: var(--c-success-300)
);

// Semantic Colors
$color-background: var(--c-background);
$color-surface: var(--c-surface);
$color-error: var(--c-error);
$color-on-primary: var(--c-on-primary);
$color-on-secondary: var(--c-on-secondary);
$color-on-background: var(--c-on-background);
$color-on-surface: var(--c-on-surface);
$color-on-error: var(--c-on-error);

// Function to access nested color values in SCSS
@function color($color-name, $shade: null) {
  @if $shade {
    @return map-get(map-get($colors, $color-name), $shade);
  } @else {
    @return map-get($colors, $color-name);
  }
}
