div,
a,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input {
	font-family: $font-primary;
	font-size: 16px;
	font-weight: 400;
    color: $c-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-primary;
    font-weight: 700;
}

h1,
.h1 {
	font-size: 64px;
    line-height: 80px;
}      

h2,
.h2 {
	font-size: 48px;
    line-height: 60px;

    @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 32px;
    }
}

h3,
.h3 {
	font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.04em;
    font-weight: 800;
}

h4,
.h4 {
	font-size: 32px;
    line-height: 45px;
    letter-spacing: -0.04em;
    font-weight: 800;

    @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 34px;
        font-weight: 700;
    }
}

h5,
.h5 {
	font-size: 18px;
    line-height: 24px;
}

h6,
.h6 {
	font-size: 20px;
    line-height: 32px;
}